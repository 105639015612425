import React, { useState } from "react"
import axios from "axios"
import { GoogleApiWrapper } from "google-maps-react"
import { Helmet } from "react-helmet"
import Geosuggest from "react-geosuggest"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import Nav from "../components/Nav"
import SEO from "../components/seo"

const styleMap = {
  satellite: "satellite-v9",
  street: "streets-v11",
  dark: "dark-v10",
  light: "light-v10",
  "satellite-streets": "satellite-streets-v11",
}

const transportMap = {
  plane: "airport",
  ferry: "ferry",
  car: "car",
  train: "rail",
}

const submit = async (
  origin,
  destination,
  mapstyle,
  transport,
  lineStyle,
  useLocationNames,
  originName,
  destinationName,
  portrait,
  email
) => {
  return axios.post(
    `https://l4z24nxnri.execute-api.us-east-1.amazonaws.com/dev/split`,
    {
      body: {
        width: portrait ? 720 : 1280,
        height: portrait ? 1280 : 720,
        deviceScaleFactor: 2,
        style: styleMap[mapstyle] || "satellite-v9",
        originLon: origin.lng,
        originLat: origin.lat,
        destinationLon: destination.lng,
        destinationLat: destination.lat,
        icon: transportMap[transport],
        centerLon: 54.6478849,
        centerLat: 24.441938,
        zoom: 5,
        locationNames: useLocationNames,
        originName,
        destinationName,
        steps: 210,
        lineStyle,
        email,
      },
    }
  )
}

const GeoCode = GoogleApiWrapper({
  apiKey: "AIzaSyA-vks8PzSdL1KDIHZGcqW_7iTNBLtxyos",
})(props => {
  return (
    <Geosuggest
      minLength={3}
      types={["geocode"]}
      {...props}
      inputClassName="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      suggestItemClassName="w-full border  py-2 px-3 text-gray-700 text-sm leading-tight"
    ></Geosuggest>
  )
})

const SecondPage = () => {
  const [origin, setOriginCoords] = useState()
  const [originName, setOriginName] = useState("")
  const [destination, setDestinationCoords] = useState()
  const [destinationName, setDestinationName] = useState("")
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [useLocationNames, setUseLocationNames] = useState(false)
  const [transport, setTransport] = useState("plane")
  const [mapStyle, setMapStyle] = useState("satellite")
  const [lineStyle, setLineStyle] = useState("solid")
  const [portrait, setPortrait] = useState(false)
  const isMapStyle = style => mapStyle === style
  const isTransport = t => transport === t
  const isLineStyle = s => lineStyle === s
  const valid = origin && destination && email
  const setOrigin = suggest => {
    if(!suggest?.location) {
      return;
    }
    setOriginCoords(suggest.location)
    setOriginName(suggest.gmaps.name)
  }
  const setDestination = suggest => {
    if(!suggest?.location) {
      return;
    }
    setDestinationCoords(suggest.location)
    setDestinationName(suggest.gmaps.name)
  }
  const [loading, setLoading] = useState(false)
  const submitForm = async e => {
    e.preventDefault()
    setLoading(true)
    if (!valid) {
      return
    }
    try {
      await submit(
        origin,
        destination,
        mapStyle,
        transport,
        lineStyle,
        useLocationNames,
        originName,
        destinationName,
        portrait,
        email
      )
      setSuccess(true)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }
  return (
    <Layout>
      <Helmet>
        <html className="smooth" />
      </Helmet>
      <SEO title="Create your animated map" />
      <Nav></Nav>
      <h2 className="w-full my-2 text-2xl md:text-3xl lg:text-5xl font-black leading-tight text-center text-gray-800">
        Generate your animated map video
      </h2>
      <div className="w-full mb-4">
        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <form className="" onSubmit={submitForm}>
        <div className="container mx-auto flex flex-wrap pt-4 pb-12 ">
          <div className="bg-gray-100  rounded shadow-lg w-full p-6 flex flex-col flex-grow flex-shrink">
            <h2 className="w-full my-2 text-2xl   leading-tight text-left text-gray-800 mb-4">
              Route
            </h2>
            <div className="w-full w-full grid grid-cols-1 md:grid-cols-2  gap-4 lg:gap-8">
              <div className=" px-3">
                <span class="text-gray-700 text-base mb-2">
                  Origin location:
                </span>
                <GeoCode
                  onSuggestSelect={suggest => setOrigin(suggest)}
                  placeholder="Origin"
                ></GeoCode>
              </div>
              <div className=" px-3">
                <span class="text-gray-700 text-base mb-2">
                  Destination location:
                </span>
                <GeoCode
                  onSuggestSelect={suggest => setDestination(suggest)}
                  placeholder="Destination"
                ></GeoCode>
              </div>
              <div className=" px-3">
                <span class="text-gray-700 text-base mb-2">Line style:</span>
                <div className="flex flex-row">
                  <label class="px-2 flex items-center cursor-pointer">
                    <div
                      className={` bg-white ${
                        isLineStyle("solid")
                          ? "shadow-inner border"
                          : "border-white shadow-lg"
                      } rounded-lg overflow-hidden`}
                    >
                      <div class="px-4 py-2 flex justify-start items-center">
                        <input
                          type="radio"
                          class="form-radio inline-block mr-4"
                          checked={isLineStyle("solid")}
                          onChange={e => setLineStyle(e.currentTarget.value)}
                          name="lineStyle"
                          value="solid"
                        ></input>
                        <h1 class="text-gray-900 font-bold text-1xl inline-block">
                          Solid
                        </h1>
                      </div>
                      <svg
                        className="h-10  w-full object-cover "
                        version="1.1"
                        viewBox="0 0 200 150"
                        width="200"
                        height="150"
                      >
                        <defs />
                        <g
                          id="Arbeitsfl_che_1"
                          fill-opacity="1"
                          stroke-opacity="1"
                          stroke="none"
                          stroke-dasharray="none"
                          fill="none"
                        >
                          <rect
                            fill="white"
                            x="0"
                            y="0"
                            width="200"
                            height="150"
                          />
                          <g id="Arbeitsfl_che_1: Layer 1">
                            <g id="Graphic_3">
                              <path
                                d="M 14210855e-21 108 C 68.31956 20.619718 134.98623 20.004364 200 106.15394"
                                stroke="black"
                                stroke-linecap="butt"
                                stroke-linejoin="round"
                                stroke-width="3"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </label>
                  <label class="px-2 flex items-center cursor-pointer">
                    <div
                      className={` bg-white ${
                        isLineStyle("dashed")
                          ? "shadow-inner border"
                          : "border-white shadow-lg"
                      } rounded-lg overflow-hidden`}
                    >
                      <div class="px-4 py-2 flex justify-start items-center">
                        <input
                          type="radio"
                          class="form-radio inline-block mr-4"
                          checked={isLineStyle("dashed")}
                          onChange={e => setLineStyle(e.currentTarget.value)}
                          name="lineStyle"
                          value="dashed"
                        ></input>
                        <h1 class="text-gray-900 font-bold text-1xl inline-block">
                          Dashed
                        </h1>
                      </div>
                      <svg
                        className="h-10  w-full object-cover "
                        viewBox="0 0 200 150"
                        width="200"
                        height="150"
                      >
                        <defs />
                        <g
                          fill-opacity="1"
                          stroke-opacity="1"
                          stroke="none"
                          stroke-dasharray="none"
                          fill="none"
                        >
                          <rect
                            fill="transparent"
                            x="0"
                            y="0"
                            width="200"
                            height="150"
                          />
                          <g id="Graphic_3">
                            <path
                              d="M 14210855e-21 108 C 68.31956 20.619718 134.98623 20.004364 200 106.15394"
                              stroke="black"
                              stroke-linecap="butt"
                              stroke-linejoin="round"
                              stroke-dasharray="12.0,12.0"
                              stroke-width="3"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </label>
                </div>
              </div>
              <div className=" px-3">
                <span class="text-gray-700 text-base mb-2">
                  Display location names:
                </span>
                <div className="flex flex-row">
                  <div class="flex items-center justify-center">
                    <label
                      for="locationNames"
                      class="flex items-center cursor-pointer"
                    >
                      <div class="relative">
                        <input
                          id="locationNames"
                          type="checkbox"
                          class="hidden"
                          checked={useLocationNames}
                          onChange={() =>
                            setUseLocationNames(!useLocationNames)
                          }
                        />
                        <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                        <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                      </div>
                      <div class="ml-3 text-gray-700 font-medium">
                        {useLocationNames ? "Yes" : "No"}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full my-1 py-4"></div>
            <h2 className="w-full my-2 text-2xl  leading-tight text-left text-gray-800 mb-4">
              Map style
            </h2>

            <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
              <div className="max-w-xs px-3">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isMapStyle("satellite")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isMapStyle("satellite")}
                        onChange={e => setMapStyle(e.currentTarget.value)}
                        name="mapstyle"
                        value="satellite"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Satellite
                      </h1>
                    </div>
                    <img
                      class="h-40 w-full object-cover "
                      src={require("../images/satellite.jpeg")}
                      alt="NIKE AIR"
                    />
                  </div>
                </label>
              </div>

              <div className="max-w-xs px-3">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isMapStyle("street")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isMapStyle("street")}
                        onChange={e => setMapStyle(e.currentTarget.value)}
                        name="mapstyle"
                        value="street"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Streets
                      </h1>
                    </div>
                    <img
                      class="h-40 w-full object-cover "
                      src={require("../images/streets.png")}
                      alt="NIKE AIR"
                    />
                  </div>
                </label>
              </div>
              <div className="max-w-xs px-3">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isMapStyle("light")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isMapStyle("light")}
                        onChange={e => setMapStyle(e.currentTarget.value)}
                        name="mapstyle"
                        value="light"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Light
                      </h1>
                    </div>
                    <img
                      class="h-40 w-full object-cover "
                      src={require("../images/light.png")}
                      alt="NIKE AIR"
                    />
                  </div>
                </label>
              </div>
              <div className="max-w-xs px-3">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isMapStyle("dark")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isMapStyle("dark")}
                        onChange={e => setMapStyle(e.currentTarget.value)}
                        name="mapstyle"
                        value="dark"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Dark
                      </h1>
                    </div>
                    <img
                      class="h-40 w-full object-cover "
                      src={require("../images/dark.png")}
                      alt="NIKE AIR"
                    />
                  </div>
                </label>
              </div>
              <div className="max-w-xs px-3">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isMapStyle("satellite-streets")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isMapStyle("satellite-streets")}
                        onChange={e => setMapStyle(e.currentTarget.value)}
                        name="mapstyle"
                        value="satellite-streets"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Satellite streets
                      </h1>
                    </div>
                    <img
                      class="h-40 w-full object-cover "
                      src={require("../images/satellite-streets.jpeg")}
                      alt="NIKE AIR"
                    />
                  </div>
                </label>
              </div>
            </div>

            <div className="w-full my-1 py-4"></div>
            <h2 className="w-full my-2 text-2xl  leading-tight text-left text-gray-800 mb-4">
              Transport
            </h2>
            <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="max-w-xs px-2 md:px-12">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isTransport("plane")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isTransport("plane")}
                        onChange={e => setTransport(e.currentTarget.value)}
                        name="transport"
                        value="plane"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Plane
                      </h1>
                    </div>
                    <svg
                      version="1.1"
                      id="airport-15"
                      class="h-20 py-5 w-full object-cover "
                      viewBox="0 0 15 15"
                    >
                      <path
                        id="path7712-0"
                        d="M15,6.8182L15,8.5l-6.5-1&#xA;&#x9;l-0.3182,4.7727L11,14v1l-3.5-0.6818L4,15v-1l2.8182-1.7273L6.5,7.5L0,8.5V6.8182L6.5,4.5v-3c0,0,0-1.5,1-1.5s1,1.5,1,1.5v2.8182&#xA;&#x9;L15,6.8182z"
                      />
                    </svg>
                  </div>
                </label>
              </div>
              <div className="max-w-xs px-2 md:px-12">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isTransport("car")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isTransport("car")}
                        onChange={e => setTransport(e.currentTarget.value)}
                        name="transport"
                        value="car"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Car
                      </h1>
                    </div>
                    <svg
                      version="1.1"
                      id="car-15"
                      class="h-20 py-5 w-full object-cover "
                      viewBox="0 0 15 15"
                    >
                      <path
                        d="M14,7c-0.004-0.6904-0.4787-1.2889-1.15-1.45l-1.39-3.24l0,0l0,0l0,0C11.3833,2.1233,11.2019,2.001,11,2H4&#xA;&#x9;C3.8124,2.0034,3.6425,2.1115,3.56,2.28l0,0l0,0l0,0L2.15,5.54C1.475,5.702,0.9994,6.3059,1,7v3.5h1v1c0,0.5523,0.4477,1,1,1&#xA;&#x9;s1-0.4477,1-1v-1h7v1c0,0.5523,0.4477,1,1,1s1-0.4477,1-1v-1h1V7z M4.3,3h6.4l1.05,2.5h-8.5L4.3,3z M3,9C2.4477,9,2,8.5523,2,8&#xA;&#x9;s0.4477-1,1-1s1,0.4477,1,1S3.5523,9,3,9z M12,9c-0.5523,0-1-0.4477-1-1s0.4477-1,1-1s1,0.4477,1,1S12.5523,9,12,9z"
                      />
                    </svg>
                  </div>
                </label>
              </div>
              <div className="max-w-xs px-2 md:px-12">
                <label className="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isTransport("ferry")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div className="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        className="form-radio inline-block mr-4"
                        checked={isTransport("ferry")}
                        onChange={e => setTransport(e.currentTarget.value)}
                        name="transport"
                        value="ferry"
                      ></input>
                      <h1 className="text-gray-900 font-bold text-1xl inline-block">
                        Ferry
                      </h1>
                    </div>
                    <svg
                      version="1.1"
                      id="ferry-15"
                      class="h-20 py-5 w-full object-cover "
                      viewBox="0 0 15 15"
                    >
                      <path
                        d="M5.33,11c0.6745,0.009,1.3262,0.245,1.85,0.67l0.26,0.23h0.05l0.31-0.28c0.9817-0.8115,2.379-0.8846,3.44-0.18L13.5,7L12,6&#xA;&#x9;V2.45c-0.0466-0.8-0.6989-1.4305-1.5-1.45H10c0.0343-0.518-0.3579-0.9657-0.8759-1C9.0828-0.0027,9.0413-0.0027,9,0H6&#xA;&#x9;C5.482-0.0343,5.0343,0.3579,5,0.8759C4.9973,0.9172,4.9973,0.9587,5,1H4.5C3.6989,1.0195,3.0466,1.65,3,2.45V6L1.5,7l2.25,4.53&#xA;&#x9;C4.2143,11.2041,4.7631,11.02,5.33,11z M4,2.45c0.0105-0.2594,0.2293-0.4611,0.4886-0.4506C4.4924,1.9996,4.4962,1.9998,4.5,2h6&#xA;&#x9;c0.259-0.0171,0.4829,0.1789,0.5,0.4379c0.0014,0.0207,0.0014,0.0414,0,0.0621v3l-3.5-2L4,5.45C4,5.45,4,2.45,4,2.45z M14,13v1&#xA;&#x9;c-0.2626,0.0194-0.5069,0.1416-0.68,0.34c-0.2717,0.2883-0.6178,0.4959-1,0.6c-0.6147,0.1815-1.2795,0.0425-1.77-0.37l-0.39-0.35&#xA;&#x9;c-0.3043-0.3026-0.7957-0.3026-1.1,0c-0.14,0.12-0.27,0.25-0.42,0.37c-0.7328,0.5856-1.7814,0.556-2.48-0.07l-0.39-0.35&#xA;&#x9;c-0.2864-0.2502-0.7136-0.2502-1,0c-0.19,0.15-0.36,0.32-0.55,0.47c-0.7043,0.5215-1.6732,0.4968-2.35-0.06l-0.31-0.27&#xA;&#x9;C1.4153,14.1443,1.2172,14.0346,1,14v-1c0.2585-0.032,0.5205,0.0169,0.75,0.14c0.2782,0.1722,0.5424,0.3661,0.79,0.58&#xA;&#x9;c0.2051,0.2235,0.5147,0.319,0.81,0.25c0.1125-0.0333,0.2177-0.0876,0.31-0.16c0.16-0.12,0.29-0.26,0.45-0.39&#xA;&#x9;c0.7106-0.5888,1.7394-0.5888,2.45,0c0.16,0.13,0.31,0.28,0.47,0.41c0.2864,0.2502,0.7136,0.2502,1,0c0.16-0.13,0.31-0.28,0.47-0.41&#xA;&#x9;c0.7123-0.5943,1.7477-0.5943,2.46,0c0.15,0.13,0.29,0.27,0.44,0.39c0.2864,0.2502,0.7136,0.2502,1,0l0.47-0.41&#xA;&#x9;C13.1895,13.1408,13.5886,12.9995,14,13z"
                      />
                    </svg>
                  </div>
                </label>
              </div>
              <div className="max-w-xs px-2 md:px-12">
                <label class="inline-flex items-center cursor-pointer">
                  <div
                    className={` bg-white ${
                      isTransport("train")
                        ? "shadow-inner border"
                        : "border-white shadow-lg"
                    } rounded-lg overflow-hidden`}
                  >
                    <div class="px-4 py-2 flex justify-start items-center">
                      <input
                        type="radio"
                        class="form-radio inline-block mr-4"
                        checked={isTransport("train")}
                        onChange={e => setTransport(e.currentTarget.value)}
                        name="transport"
                        value="train"
                      ></input>
                      <h1 class="text-gray-900 font-bold text-1xl inline-block">
                        Train
                      </h1>
                    </div>
                    <svg
                      version="1.1"
                      id="rail-15"
                      class="h-20 py-5 w-full object-cover "
                      viewBox="0 0 15 15"
                    >
                      <path
                        d="M 3 1 C 2.4477 1 2 1.4477 2 2 L 2 10 C 2 10.5523 2.4477 11 3 11 L 12 11 C 12.5523 11 13 10.5523 13 10 L 13 2 C 13 1.4477 12.5523 1 12 1 L 3 1 z M 5.75 1.5 L 5.7597656 1.5 L 9.2597656 1.5 C 9.3978656 1.5 9.5097656 1.6119 9.5097656 1.75 C 9.5097656 1.8881 9.3978656 2 9.2597656 2 L 5.75 2 C 5.6119 2 5.5 1.8881 5.5 1.75 C 5.5 1.6119 5.6119 1.5 5.75 1.5 z M 3.5 3 L 7 3 L 7 7 L 3.5 7 C 3.2239 7 3 6.7761 3 6.5 L 3 3.5 C 3 3.2239 3.2239 3 3.5 3 z M 8 3 L 11.5 3 C 11.7761 3 12 3.2239 12 3.5 L 12 6.5 C 12 6.7761 11.7761 7 11.5 7 L 8 7 L 8 3 z M 5 8 C 5.5523 8 6 8.4477 6 9 C 6 9.5523 5.5523 10 5 10 C 4.4477 10 4 9.5523 4 9 C 4 8.4477 4.4477 8 5 8 z M 10 8 C 10.5523 8 11 8.4477 11 9 C 11 9.5523 10.5523 10 10 10 C 9.4477 10 9 9.5523 9 9 C 9 8.4477 9.4477 8 10 8 z M 10.445312 11.994141 C 10.380597 11.999652 10.314981 12.018581 10.253906 12.050781 C 10.030606 12.168381 9.9302313 12.433922 10.019531 12.669922 L 10.189453 13 L 4.8105469 13 L 4.9394531 12.730469 C 5.0371531 12.472169 4.9067375 12.183637 4.6484375 12.085938 C 4.4124375 11.996738 4.1468969 12.097113 4.0292969 12.320312 L 3.0292969 14.320312 C 3.0080969 14.377912 2.9986 14.4387 3 14.5 C 3 14.7761 3.2239 15 3.5 15 C 3.6802 14.999 3.8450875 14.899434 3.9296875 14.740234 L 3.9296875 14.689453 L 4 14.689453 L 4.3105469 14 L 10.689453 14 L 11 14.689453 L 11 14.740234 C 11.0846 14.899434 11.249488 14.999 11.429688 15 C 11.705787 15 11.929688 14.7761 11.929688 14.5 C 11.949587 14.4212 11.949587 14.338566 11.929688 14.259766 L 10.929688 12.259766 C 10.833163 12.076541 10.639459 11.977608 10.445312 11.994141 z "
                        id="path3"
                      />
                    </svg>
                  </div>
                </label>
              </div>
            </div>
            
            <div className="w-full my-1 py-2"></div>

            <h2 className="w-full my-2 text-2xl   leading-tight text-left text-gray-800 mb-4">
              Video
            </h2>
            <div className="w-full w-full grid grid-cols-1 md:grid-cols-2  gap-4 lg:gap-8">
            <div className=" px-3">
            <span class="text-gray-700 text-base mb-2">Orientation:</span>
            <div className="flex flex-row">
              <label class="px-2 flex items-center cursor-pointer">
                <div
                  className={` bg-white ${
                    !portrait
                      ? "shadow-inner border"
                      : "border-white shadow-lg"
                  } rounded-lg overflow-hidden`}
                >
                  <div class="px-4 py-2 flex justify-start items-center">
                    <input
                      type="radio"
                      class="form-radio inline-block mr-4"
                      checked={!portrait}
                      onChange={e => setPortrait(false)}
                      name="portrait"
                    ></input>
                    <h1 class="text-gray-900 font-bold text-1xl inline-block">
                      Landscape
                    </h1>
                  </div>
                  <svg className="h-12  w-full object-cover " xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M1.01 7L1 17c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H3c-1.1 0-1.99.9-1.99 2zM19 7v10H5V7h14z"/></svg>
                </div>
              </label>
              <label class="px-2 flex items-center cursor-pointer">
                <div
                  className={` bg-white ${
                    portrait
                      ? "shadow-inner border"
                      : "border-white shadow-lg"
                  } rounded-lg overflow-hidden`}
                >
                  <div class="px-4 py-2 flex justify-start items-center">
                    <input
                      type="radio"
                      class="form-radio inline-block mr-4"
                      checked={portrait}
                      onChange={e => setPortrait(true)}
                      name="portrait"
                    ></input>
                    <h1 class="text-gray-900 font-bold text-1xl inline-block">
                      Portrait
                    </h1>
                  </div>
                  <svg className="h-12 py-1 w-full object-cover " xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 1.01L7 1c-1.1 0-1.99.9-1.99 2v18c0 1.1.89 2 1.99 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z"/></svg>
                </div>
              </label>
            </div>
          </div>
          
            
            </div>
            <div className="w-full my-1 py-2"></div>
            

            <div className="text-center pt-6 px-3 lg:px-0 flex flex-col md:flex-row justify-center mx-auto items-start">
              {loading && (
                <div class="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
              {!success && !loading && (
                <>
                  <label class="inline-block max-w-md mx-auto mb-4 md:mx-0 md:mb-0">
                    <input
                      class="form-input  p-4 block w-full max-w-md"
                      placeholder="Your email"
                      required
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <span class="text-gray-700 text-sm">
                      We need an email address to send the download link to you.
                    </span>
                  </label>
                  <button
                    type="submit"
                    disabled={!valid}
                    className={`${
                      valid
                        ? "button shadow-lg hover:underline"
                        : "border cursor-not-allowed"
                    } transition duration-150 inline-block mx-auto lg:mx-0  md:mx-0 text-gray-800 font-extrabold rounded  py-4 px-8  w-48`}
                  >
                    Generate
                  </button>
                </>
              )}
              {success && !loading && (
                <div
                  class="flex items-center bg-green-500 text-white text-md font-bold px-8 py-4"
                  role="alert"
                >
                  <svg
                    class="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>
                    Your video is being rendered! Please check your inbox after
                    some minutes for the download link.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <Footer></Footer>
    </Layout>
  )
}

export default SecondPage
